<template>
  <v-container>
    <v-row >
      <v-col cols="12" sm="6" offset-sm="3">
        <v-card v-if="!sent">
          <v-card-text>
            Please enter your email address to continue
            <v-container>
              <v-form ref="form" @submit.prevent="resetPasswordInit">
                <v-col cols="12">
                  <v-text-field
                    v-model="email"
                    label="E-mail address"
                    v-validate="'required|email'"
                    data-vv-as="email"
                    name="email"
                    :error-messages="errors.collect('email')"
                  ></v-text-field>
                </v-col>
                <v-col cols="12">
                  <v-btn color="primary" type="submit">Continue</v-btn>
                </v-col>
              </v-form>
            </v-container>
          </v-card-text>
        </v-card>
        <v-card v-if="sent">
          <v-card-text>
            A password reset email has been sent to your email address. Please check your email.
            <Br/>
            <router-link tag="a" to="/signin">Proceed to login</router-link>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import firebase from "firebase";
import Vue from "vue";
import VeeValidate from "vee-validate";

Vue.use(VeeValidate);

export default {
  data: () => ({
    email: "",
    sent: false
  }),
  computed: {},
  watch: {},
  methods: {
    resetPasswordInit() {
      this.$validator.validateAll().then(result => {
        if (result) {
          firebase
            .auth()
            .sendPasswordResetEmail(this.email)
            .then(data => {
              console.log(data);
              this.$store.dispatch("setToast", {
                message: `Email sent to ${this.email}`,
                color: "green darken-4"
              });
              this.sent = true;
            })
            .catch(error => {
              console.log(error);
              this.$store.dispatch("setToast", {
                message: "Invalid email please correct before continuing",
                color: "red darken-4"
              });
            });

          return;
        } else {
          this.$store.dispatch("setToast", {
            message: "Invalid email please correct before continuing",
            color: "red darken-4"
          });
        }
      });
    }
  }
};
</script>
